;(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .controller("CommunityLocationCtrl", ['$scope', '$timeout', function($scope, $timeout) {

        $scope.isKey = false;
        $scope.map = null;
        $scope.marker = null;
        $scope.salesMarker = null;
        $scope.isMapDataLoaded = false;
        $scope.isMapsLoaded = false;
        $scope.infoWindowContent = '';
        $scope.salesInfoWindowContent = '';

        function generateInfoWindowContent(title, lat, lng) {
          var content = document.createElement('div');
          var titleContent = title || $scope.community.name;
          var directionsLat = lat || $scope.community.latitude;
          var directionsLng = lng || $scope.community.longitude;

          content.innerHTML = '<div class="community-location-popup-window"><p class="h5 media-heading">' + titleContent + '</p><a class="button get-directions-link" href="https://www.google.com/maps/search/?api=1&query=' + directionsLat + ',' + directionsLng + '" target="_blank">SEE LOCATION</a></div>';

          return content;
        }

        function fitMapToMarkers() {
          var bounds = new google.maps.LatLngBounds();

          bounds.extend(new google.maps.LatLng($scope.community.latitude, $scope.community.longitude));
          bounds.extend(new google.maps.LatLng($scope.community.sales_office_latitude, $scope.community.sales_office_longitude));

          bounds.extend(
            new google.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getSouthWest().lng() - 0.005),
            new google.maps.LatLng(bounds.getNorthEast().lat() - 0.005, bounds.getSouthWest().lng() + 0.005)
          );
          $scope.map.fitBounds(bounds);
        }

        function setupMap() {

          var salesLat = $scope.community.sales_office_latitude && !isNaN($scope.community.sales_office_latitude) ? parseFloat($scope.community.sales_office_latitude) : 30.3298767;
          var salesLng = $scope.community.sales_office_longitude && !isNaN($scope.community.sales_office_longitude) ? parseFloat($scope.community.sales_office_longitude) : -97.6948925;

          var communityLat = $scope.community.latitude && !isNaN($scope.community.latitude) ? parseFloat($scope.community.latitude) : 30.3298767;
          var communityLng = $scope.community.longitude && !isNaN($scope.community.longitude) ? parseFloat($scope.community.longitude) : -97.6948925;

          if (salesLat !== communityLat) {
            $scope.isKey = true;
          }

          $scope.map = new google.maps.Map(document.getElementById('communityLocationMap'), {
            mapId: '838174bfafb5e02e',
            center: new google.maps.LatLng(communityLat, communityLng),
            zoom: 15,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.TOP_LEFT
            }
          });

          $scope.infoWindow = new google.maps.InfoWindow({ });
          $scope.infoWindowContent = generateInfoWindowContent();

          var markerContent = document.createElement('div');
          var markerImage = document.createElement('img');
          markerImage.src = '/images/site/pin-blue.png';
          markerContent.appendChild(markerImage);

          $scope.marker = new google.maps.marker.AdvancedMarkerElement({
            map: $scope.map,
            position: new google.maps.LatLng(communityLat, communityLng),
            content: markerContent,
            gmpClickable: true,
          });

          if (salesLat !== communityLat) {
            $scope.infoWindowContent = generateInfoWindowContent('Community Location', communityLat, communityLng);
            $scope.salesInfoWindowContent = generateInfoWindowContent('Sales Office', salesLat, salesLng);

            var salesMarkerContent = document.createElement('div');
            var salesMarkerImage = document.createElement('img');
            salesMarkerImage.src = '/images/site/pin-red.png';
            salesMarkerContent.appendChild(salesMarkerImage);

            $scope.salesMarker = new google.maps.marker.AdvancedMarkerElement({
              map: $scope.map,
              position: new google.maps.LatLng(salesLat, salesLng),
              content: salesMarkerContent,
            });

            fitMapToMarkers();
          }

          $scope.marker.addListener('click', function() {
            $timeout(function() {
              $scope.infoWindow.setContent($scope.infoWindowContent);
              $scope.infoWindow.open($scope.map, $scope.marker);
            });
          });

          if ($scope.salesMarker) {
            $scope.salesMarker.addListener('click', function() {
              $timeout(function() {
                $scope.infoWindow.setContent($scope.salesInfoWindowContent);
                $scope.infoWindow.open($scope.map, $scope.salesMarker);
              });
            });
          }
        }

        $scope.$watch("community", function(value, oldValue) {
          if (value && value.id && !$scope.isMapDataLoaded) {
            $scope.isMapDataLoaded = true;
            if ($scope.isMapsLoaded) {
              setupMap();
            }
          }
        }, true);

        Promise.all([
          google.maps.importLibrary('marker'),
          google.maps.importLibrary('maps')
        ]).then(function() {
          $scope.isMapsLoaded = true;
          if ($scope.isMapDataLoaded) {
            setupMap();
          }
        });

      }
    ])
  ;

})();