(function() {

  'use strict';
  /* global angular */
  /* global regionOptions */
  /* global communityOptions */
  /* global btoa */

  angular
    .module("Milestone")
    .controller('QuickDeliveryHomesRevampCtrl', [
      "$scope",
      "$http",
      "$timeout",
      "$window",
      "$document",
      "$modal",
      function ($scope, $http, $timeout, $window, $document, $modal) {
        var lastFilterParams = '';
        var localStore = {};

        $scope.urlSearchParams = new URLSearchParams($window.location.search);
        $scope.dropdownMenu1 = false;
        $scope.dropdownMenu2 = false;
        $scope.dropdownMenu3 = false;
        $scope.isFiltersApplied = false;
        $scope.isLoading = true;

        $scope.homeFilters = {
          regionId: '',
          communityId: '',
          price: '',
          beds: '',
          baths: '',
          sqft: '',
          availability: '',
          sortBy: '',
          page: 1,
        };
        $scope.previouslySelectedRegionId = $scope.homeFilters.regionId;
        $scope.previouslySelectedCommunityId = $scope.homeFilters.communityId;
        $scope.regionOptions = regionOptions || [];
        $scope.communityOptions = communityOptions || [];
        $scope.filteredCommunityOptions = [];
        $scope.filteredCommunityOptions = getFilteredCommunityOptions();

        $scope.pagination = {
          lastPage: 1,
          currentPage: 1,
          pages: [],
        };

        $scope.$watch('dropdownMenu1', function(is, was) {
          var numDropdownsOpen = 0;

          if ($scope.dropdownMenu1) {
            numDropdownsOpen++;
          }
          if ($scope.dropdownMenu2) {
            numDropdownsOpen++;
          }
          if ($scope.dropdownMenu3) {
            numDropdownsOpen++;
          }

          $timeout(function() {
            if (is) {
              $scope.dropdownMenu2 = false;
              $scope.dropdownMenu3 = false;
            }

            if (numDropdownsOpen > 0) {
              $document[0].addEventListener('keydown', $scope.closeFilterDropdowns, false);
            }
          });


        });

        $scope.$watch('dropdownMenu2', function(is, was) {
          var numDropdownsOpen = 0;

          if ($scope.dropdownMenu1) {
            numDropdownsOpen++;
          }
          if ($scope.dropdownMenu2) {
            numDropdownsOpen++;
          }
          if ($scope.dropdownMenu3) {
            numDropdownsOpen++;
          }

          $timeout(function() {
            if (is) {
              $scope.dropdownMenu1 = false;
              $scope.dropdownMenu3 = false;
            }

            if (numDropdownsOpen > 0) {
              $document[0].addEventListener('keydown', $scope.closeFilterDropdowns, false);
            }
          });
        });

        $scope.$watch('dropdownMenu3', function(is, was) {
          var numDropdownsOpen = 0;

          if ($scope.dropdownMenu1) {
            numDropdownsOpen++;
          }
          if ($scope.dropdownMenu2) {
            numDropdownsOpen++;
          }
          if ($scope.dropdownMenu3) {
            numDropdownsOpen++;
          }

          $timeout(function() {
            if (is) {
              $scope.dropdownMenu1 = false;
              $scope.dropdownMenu2 = false;
            }

            if (numDropdownsOpen > 0) {
              $document[0].addEventListener('keydown', $scope.closeFilterDropdowns, false);
            }
          });


        });

        $scope.$watch('homeFilters', function(is, was) {
          var selectedCommunityOption = $scope.communityOptions.find(function(community) {
            return community.id == $scope.homeFilters.communityId;
          });
          var selectedRegionOption = $scope.regionOptions.find(function(region) {
            return region.id == $scope.homeFilters.regionId;
          });

          $timeout(function() {
            var communityBelongsToRegion = selectedRegionOption && selectedCommunityOption && selectedRegionOption.id == selectedCommunityOption.region_id;

            $scope.filteredCommunityOptions = getFilteredCommunityOptions();

            if ($scope.homeFilters.regionId && $scope.homeFilters.communityId && !communityBelongsToRegion) {
              Object.assign($scope.homeFilters, {
                communityId: '',
              });
            }

            if (!$scope.homeFilters.regionId && $scope.previouslySelectedRegionId) {
              Object.assign($scope.homeFilters, {
                communityId: '',
              });
            }

            if ($scope.homeFilters.communityId && !$scope.homeFilters.regionId && !$scope.previouslySelectedCommunityId) {
              Object.assign($scope.homeFilters, {
                regionId: selectedCommunityOption.region_id + '',
              });
            }

            $scope.previouslySelectedRegionId = $scope.homeFilters.regionId;
            $scope.previouslySelectedCommunityId = $scope.homeFilters.communityId;

            updateUrlParams();
            getFilteredHomes();
          });
        }, true);

        $scope.open = function (home) {
          if (home.activate_details_page) {
            $window.open(home.url, '_blank');
            return;
          }

          $scope.home = home;
          $scope.residence = home.residence;

          var modalInstance = $modal.open({
            templateUrl: 'availableHomeModalContent.html',
            controller: 'AvailableHomeModalInstanceCtrl',
            windowClass: 'modal-available-home',
            animation: true,
            scope: $scope
          });
        };

        $scope.viewFloorPlansAndElevations = function (home) {
          $scope.home = home;
          $scope.residence = home.residence;

          var modalInstance = $modal.open({
            templateUrl: 'availableHomeModalContent.html',
            controller: 'AvailableHomeModalInstanceCtrl',
            windowClass: 'modal-available-home',
            animation: true,
            scope: $scope
          });
        };

        $scope.gallery = function(home) {
          $scope.home = home;

          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'myModalContent.html',
            controller: 'ModalInstanceCtrl',
            windowClass: 'custom-carousel-modal',
            size: 'lg',
            scope: $scope
          });
        };

        $scope.clearFilters = function() {
          $timeout(function() {
            Object.assign($scope.homeFilters, {
              regionId: '',
              communityId: '',
              price: '',
              beds: '',
              baths: '',
              sqft: '',
              availability: '',
              sortBy: '',
            });
          });
        };

        $scope.onPageClick = function(page) {
          $scope.pagination.currentPage = page;
          $scope.homeFilters.page = page;
        };

        $scope.onNextClick = function() {
          if ($scope.pagination.currentPage === $scope.pagination.lastPage) {
            return;
          }

          $scope.onPageClick($scope.pagination.currentPage + 1);
        };

        $scope.onPrevClick = function() {
          if ($scope.pagination.currentPage === 1) {
            return;
          }

          $scope.onPageClick($scope.pagination.currentPage - 1);
        };

        $scope.closeFilterDropdowns = function(event) {
          if (!event || event.key === 'Escape') {
            if ($scope.dropdownMenu1) {
              $document.find('#dropdownMenu1').focus();
            }
            if ($scope.dropdownMenu2) {
              $document.find('#dropdownMenu2').focus();
            }
            if ($scope.dropdownMenu3) {
              $document.find('#dropdownMenu3').focus();
            }

            $timeout(function() {
              $scope.dropdownMenu1 = false;
              $scope.dropdownMenu2 = false;
              $scope.dropdownMenu3 = false;
              $document[0].removeEventListener('keydown', $scope.closeFilterDropdowns);
            });
          }
        }

        function getFilteredCommunityOptions() {
          var filteredCommunityOptions = $scope.homeFilters.regionId
            ? $scope.communityOptions.filter(function(community) {
              return community.region_id == $scope.homeFilters.regionId;
            })
            : $scope.communityOptions.filter(function(community) {
              return community;
            });

          return filteredCommunityOptions;
        }

        function setFiltersFromUrlParams() {
          var urlSearchParams = $scope.urlSearchParams;
          var params = $scope.homeFilters;

          if (urlSearchParams.has('regionId')) {
            params.regionId = urlSearchParams.get('regionId');
          }

          if (urlSearchParams.has('communityId')) {
            params.communityId = urlSearchParams.get('communityId');
          }

          if (urlSearchParams.has('price')) {
            params.price = urlSearchParams.get('price');
          }

          if (urlSearchParams.has('beds')) {
            params.beds = urlSearchParams.get('beds');
          }

          if (urlSearchParams.has('baths')) {
            params.baths = urlSearchParams.get('baths');
          }

          if (urlSearchParams.has('sqft')) {
            params.sqft = urlSearchParams.get('sqft');
          }

          if (urlSearchParams.has('availability')) {
            params.availability = urlSearchParams.get('availability');
          }

          if (urlSearchParams.has('sortBy')) {
            params.sortBy = urlSearchParams.get('sortBy');
          }

          if (urlSearchParams.has('page')) {
            params.page = urlSearchParams.get('page');
          }
        }

        function updateUrlParams() {
          var params = $scope.homeFilters;
          Object.keys(params).forEach(function(key) {
            if (!params[key] || params[key] === null || params[key] === undefined || params[key] === '') {
              $scope.urlSearchParams.delete(key);
              return;
            }

            if (key === 'page' && params[key] == 1) {
              $scope.urlSearchParams.delete(key);
              return;
            }

            $scope.urlSearchParams.set(key, params[key]);
          });

          $window.history.replaceState({}, '', $window.location.pathname + '?' + $scope.urlSearchParams.toString());
        }

        function getFilterHash(filters) {
          var sortedKeys = Object.keys(filters).sort();
          var paramString = sortedKeys.map(function (key) {
            return key + '=' + filters[key];
          }).join('&');
          var hash = btoa(paramString);

          return hash;
        }

        function getFilteredHomes(page) {
          $scope.homeFilters.page = page || $scope.pagination.currentPage;


          var params = Object.keys($scope.homeFilters).reduce(function(acc, key) {
            if ($scope.homeFilters[key] && $scope.homeFilters[key] !== null && $scope.homeFilters[key] !== undefined && $scope.homeFilters[key] !== '') {
              acc[key] = $scope.homeFilters[key];
            }

            return acc;
          }, {});
          var stringifiedParams = JSON.stringify(params);

          if (stringifiedParams === lastFilterParams) {
            return;
          }

          lastFilterParams = stringifiedParams;

          var hash = getFilterHash($scope.homeFilters);

          if (localStore[hash]) {
            $scope.homes = localStore[hash].homes;
            $scope.pagination.currentPage = localStore[hash].currentPage;
            $scope.pagination.lastPage = localStore[hash].lastPage;
            $scope.pagination.pages = localStore[hash].pages;
            $scope.isLoading = false;
            return;
          }

          $scope.isFiltersApplied = false;
          $scope.isLoading = true;

          $http.get('/api/homes', {
            params: params,
          }).then(function(response) {
            var pages = Array.from({ length: response.data.last_page }, function(_, i) { return i + 1; });
            pages.shift();
            pages.pop();
            $scope.homes = response.data.data;
            $scope.pagination.lastPage = response.data.last_page;
            $scope.pagination.currentPage = response.data.current_page;
            $scope.pagination.pages = pages;
            $scope.isFiltersApplied = response.data.isFiltered;
            $scope.isLoading = false;

            localStore[hash] = {
              homes: $scope.homes,
              currentPage: $scope.pagination.currentPage,
              lastPage: $scope.pagination.lastPage,
              pages: $scope.pagination.pages,
            };
          }).catch(function(error) {
            console.log(error);
            $scope.isLoading = false;
          });
        }
        setFiltersFromUrlParams();
        updateUrlParams();
        getFilteredHomes();
      }
    ])
    .controller('AvailableHomeModalInstanceCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {
      if ($scope.residence === undefined) {
        return null;
      }

      $scope.floorplanElevation = $scope.residence;
      $scope.images = $scope.floorplanElevation.elevations;

      $scope.activeTab = 'floorplans';

      $scope.ok = function () {
        $modalInstance.close();
      };
    }])

    .controller('ModalInstanceCtrl', ["$scope", '$modalInstance',
      function ($scope, $modalInstance) {

        $scope.myInterval = false;
        $scope.noWrapSlides = false;

        $scope.cancel = function () {
          $modalInstance.dismiss('cancel');
        };

      }
    ])
  ;

})();
