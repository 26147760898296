(function() {

  'use strict';
  /* global angular, google, Promise */

  angular
    .module("Milestone.findYourHome")

    .controller("FindYourHomeCtrl", ["$scope", "$timeout", "CommunitiesListDataService", "UserDataService", "$http", "$rootScope", "$routeParams", "$location",
      function($scope, $timeout, CommunitiesListDataService, UserDataService, $http, $rootScope, $routeParams, $location) {

        $scope.tab = 'communities';
        $scope.routeParams = $routeParams;
        $scope.seo_name = $scope.routeParams.seoName;

        $scope.regions = CommunitiesListDataService.regions;

        $scope.mobileCommunity = function(){
          $location.path('/find-your-home/' + $scope.seo_name);
        }

        $scope.activeRegion = function() {
          var res = $scope.regions.filter(function(region) {
            return region.seo_name === $scope.seo_name;
          });
          return res[0];
        };

        $scope.communityListOpened = false;

        $scope.toggleCommunityList = function() {
          $scope.communityListOpened = !$scope.communityListOpened;
        };

        $scope.selectedTabIndex = 0;

        $scope.selectTab = function(tabIndex) {
          $scope.selectedTabIndex = tabIndex;
        };

        $scope.naturalSort = function(community) {
          return community.name.replace(/^The /, '');
        };

        $scope.sortColumn = $scope.naturalSort;
        $scope.reverse = false;

        // got to use this instead of == to support arrays
        var array_equals = function(a, b) {
          // if !a XOR !b then we can assume false
          /*jslint bitwise: true */
          if ( (a === undefined ? 1 : 0) ^ (b === undefined ? 1 : 0) ) {
            return false;
          }

          if (a instanceof Function){
            return a === b;
          }

          if (typeof a === 'string') {
            return a === b;
          }

          return a.length === b.length && a.every(function(v, i) {
              return v === b[i];
            });
        };

        $scope.sort = function(eq, column) {

          if (array_equals($scope.sortColumn, column)) {

            if (eq % 2 === 0 && $rootScope.is_phone) {
              $scope.reverse = false;
            } else if ($rootScope.is_phone) {
              $scope.reverse = true;
            } else {
              $scope.reverse = !$scope.reverse;
            }

            return;
          }

          $scope.sortColumn = column;
          $scope.reverse = false;
        };

        $scope.mobileSort = function(selected) {
          $scope.sortColumn = selected.filterBy;
          $scope.reverse = selected.sort === 'asc' ? false : true;
        };

        $scope.sortArrows = function(column) {
          var asc = array_equals($scope.sortColumn, column) && !$scope.reverse;
          var desc = array_equals($scope.sortColumn, column) && $scope.reverse;

          var res = {
            'indicator': true,
            'indicator-asc': asc,
            'indicator-desc': desc
          };

          return res;
        };

        $scope.dropdownList = [
          {
            filterName: 'Community Name (A-Z)',
            filterBy: 'name',
            sort: 'asc'
          },
          {
            filterName: 'Community Name (Z-A)',
            filterBy: 'name',
            sort: 'desc'
          },
          {
            filterName: 'Price (low to high)',
            filterBy: 'price',
            sort: 'asc'
          },
          {
            filterName: 'Price (high to low)',
            filterBy: 'price',
            sort: 'desc'
          },
          {
            filterName: 'Sqft (low to high)',
            filterBy: ['sqft_low', 'sqft_high'],
            sort: 'asc'
          },
          {
            filterName: 'Sqft (high to low)',
            filterBy: ['sqft_low', 'sqft_high'],
            sort: 'desc'
          },
          {
            filterName: 'Bed (low to high)',
            filterBy: ['bed_low', 'bed_high'],
            sort: 'asc'
          },
          {
            filterName: 'Bed (high to low)',
            filterBy: ['bed_low', 'bed_high'],
            sort: 'desc'
          },
          {
            filterName: 'Bath (low to high)',
            filterBy: ['bath_low', 'bath_high'],
            sort: 'asc'
          },
          {
            filterName: 'Bath (high to low)',
            filterBy: ['bath_low', 'bath_high'],
            sort: 'desc'
          }
        ];

        $scope.regionSort = $scope.dropdownList[0];



        $scope.signup = {};
        UserDataService.getUser().then(function (user) {
          $scope.signup = angular.copy(user);
          $scope.signup.origin = window.location.href;
          $scope.signup.type = 'signUpMicrosite';
          $scope.signup.communities = [];
          //$scope.signup.communities[0] = $scope.community.id;
          $scope.signup.form_id = 177545;
          $scope.signup.guid = window.getLassoGuid();
        });

        $scope.hideForm = false;
        $scope.showMessage = false;
        $scope.showLoader = false;
        $scope.triedSignupSubmit = false;

        $scope.checkSignupFormValidation = function (form) {
          $scope.triedSignupSubmit = true;

          if (!form.$valid) {
            return;
          }

          processQuickSignUpForm();
        };

        var processQuickSignUpForm = function () {
          $scope.hideForm = true;
          $scope.showLoader = true;
          $http({
            method: 'POST',
            url: '/form/to-database',
            data: $.param($scope.signup), // pass in data as strings
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          }).success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Quick Signup'
              };

              // Log action in clicky
              clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-microsite']);

              if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
                $window.google_trackConversion({
                  google_conversion_id: googleConversionId,
                  google_conversion_language: 'en',
                  google_conversion_format: '3',
                  google_conversion_color: 'ffffff',
                  google_conversion_label: googleConversionLabel,
                  google_remarketing_only: false
                });
              }

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
              $scope.hideForm = false;
            }
          });
        };

      }
    ])

    .controller('FindYourHomeMapCtrl', ['$scope', 'RegionDataPromiseService', '$timeout', 'CommunitiesListDataService',
      function($scope, RegionDataPromiseService, $timeout, CommunitiesListDataService) {

        $scope.tab = 'map';

        $scope.regions = [];
        $scope.communities = [];
        $scope.map = null;
        $scope.markers = [];
        $scope.infoWindow = null;
        $scope.isMapDataLoaded = false;
        $scope.isMapsLoaded = false;

        $scope.closeDialog = function() {
          $scope.infoWindow.close();
        };

        RegionDataPromiseService
          .promise
          .then(function(response) {
            console.log("RegionDataPromiseService", response);
            $scope.regions = response.data.regions;
            $scope.communities = $scope.regions.flatMap(function(region) {
              return region.communities;
            });
            $scope.community = null;

            $scope.isMapDataLoaded = true;
            if ($scope.isMapsLoaded) {
              initMarkers();
            }
          });

        function initMarkers() {
          $scope.markers = [];
          $scope.communities.forEach(function(community) {
            var lat = community.latitude && !isNaN(community.latitude) ? community.latitude : 0;
            var lng = community.longitude && !isNaN(community.longitude) ? community.longitude : 0

            if (!lat || !lng) {
              return;
            }

            var markerContent = document.createElement("div");
            var markerImage = document.createElement("img");
            markerImage.src = "/images/site/blue-icon-pin.png";
            markerImage.width = "46";
            markerImage.height = "58";
            markerContent.appendChild(markerImage);

            var marker = new google.maps.marker.AdvancedMarkerElement({
              position: new google.maps.LatLng(lat, lng),
              map: $scope.map,
              content: markerContent,
              gmpClickable: true,
            });

            marker.addListener("click", function() {
              $timeout(function() {
                $scope.community = community;
                $scope.infoWindow.show();
              });
            });

            $scope.markers.push(marker);
          });
        }

        function initMap() {
          var mapElem = document.getElementById("map");

          $scope.map = new google.maps.Map(mapElem, {
            mapId: "838174bfafb5e02e",
            center: new google.maps.LatLng(30.262, -97.934),
            zoom: 10,
            clickableIcons: false,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            }
          });

          $scope.infoWindow = document.querySelector("#info-window-community");

          $scope.isMapsLoaded = true;
          if ($scope.isMapDataLoaded) {
            initMarkers();
          }


        }


        Promise.all([
          google.maps.importLibrary("maps"),
          google.maps.importLibrary("marker"),
        ]).then(function() {
          initMap();
        });

      }
    ])
  ;

})();
