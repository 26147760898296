/*jslint white: true, nomen: true */
/*global angular, googleApiKey */
(function commonServicesModuleInit() {

  /*
    In this module, we can store configuration and other services
    that will be shared by SmartEdit and Milestone.
  */
  'use strict';

  /* global angular, googleApiKey */

  angular
    .module("commonServices", []);

}());
