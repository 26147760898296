;(function(){

    'use strict';
    /* global google, Promise */

    angular
    .module("Milestone")

    .controller("ComingSoonCtrl", ["$scope", "$modal", "$timeout",  "CommunitiesListDataService", "ComingSoonCommunitiesListDataService",
        function($scope, $modal, $timeout, CommunitiesListDataService, ComingSoonCommunitiesListDataService) {

            $scope.regions = CommunitiesListDataService.regions;
            $scope.comingSoonCommunities = ComingSoonCommunitiesListDataService.communities;
            $scope.map = null;
            $scope.markers = [];
            $scope.isMapDataLoaded = false;
            $scope.isMapsLoaded = false;

            function fitMapToMarkers() {
              var bounds = new google.maps.LatLngBounds();
              $scope.markers.forEach(function(marker) {
                bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
              });
              bounds.extend(
                new google.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getSouthWest().lng() - 0.005),
                new google.maps.LatLng(bounds.getNorthEast().lat() - 0.005, bounds.getSouthWest().lng() + 0.005)
              );
              $scope.map.fitBounds(bounds);
            }

            function setupMap() {
              $scope.map = new google.maps.Map(document.getElementById('map'), {
                mapId: '838174bfafb5e02e',
                center: new google.maps.LatLng(30.3298767, -97.6948925),
                zoom: 9,
                clickableIcons: false,
                disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.RIGHT_CENTER
                }
              });
            }

            $scope.$watch("comingSoonCommunities", function(value, oldValue) {
              if (value.length > 0 && !$scope.isMapDataLoaded) {
                $scope.isMapDataLoaded = true;
                if ($scope.isMapsLoaded) {
                  addMarkers();
                }
              }
            }, true);

            function addMarkers() {
              $scope.markers = [];

              $scope.comingSoonCommunities.forEach(function(community) {
                var lat = community.latitude && !isNaN(community.latitude) ? parseFloat(community.latitude) : 0;
                var lng = community.longitude && !isNaN(community.longitude) ? parseFloat(community.longitude) : 0;

                if (!lat || !lng) {
                  return;
                }

                var markerContent = document.createElement('div');
                var markerImage = document.createElement('img');
                markerImage.src = '/images/site/blue-icon-pin.png';
                markerImage.alt = community.name;
                markerImage.width = "46";
                markerImage.height = "58";
                markerContent.appendChild(markerImage);

                var marker = new google.maps.marker.AdvancedMarkerElement({
                  map: $scope.map,
                  position: new google.maps.LatLng(lat, lng),
                  gmpClickable: true,
                  content: markerContent
                });

                marker.addListener('click', function() {
                  $scope.open(community);
                });

                $scope.markers.push(marker);
                fitMapToMarkers();
              });
            }

            // Redirect to selected community
            $scope.$watch("redirect.community", function(value, oldValue) {
                if (value == oldValue || _.isEmpty(value)) { return; }
                var selected = {};


                _.map($scope.regions, function (r) {
                    _.map(r.communities, function(c) {
                        if (c.id == value) {
                            selected.region = r.seo_name;
                            selected.community = c.seo_name;
                        }
                    });
                });

                window.location = '/' + selected.region + '/' + selected.community;
            });

            $scope.open = function(object) {
                $scope.modalCommunity = object;
                var modalInstance = $modal.open({
                    templateUrl: 'comingSoonModal.html',
                    controller: 'comingSoonModalCtrl',
                    windowClass: 'modal-auto-size fade',
                    animation: true,
                    size: 'md',
                    scope: $scope
                });
            };

            Promise.all([
              google.maps.importLibrary('marker'),
              google.maps.importLibrary('maps')
            ]).then(function() {
              setupMap();
              $scope.isMapsLoaded = true;
            });

        }
    ])

    ;

})();