/* global google, Promise, document, $ */

angular
  .module("Milestone")

  .controller('regionalMapCtrl', ['$scope', 'RegionsDataService', '$timeout',
    function($scope, RegionsDataService, $timeout) {
      'use strict';

      $scope.map = null;
      $scope.markers = [];
      $scope.communities = [];
      $scope.isMapsLoaded = false;
      $scope.isMapDataLoaded = false;
      $scope.infoWindow = null;
      $scope.community = null;

      $scope.resizeMap = function() {
        var mapsize = $('.map-section').height();
        var mapContainerOffset = mapsize + $(".navbar-fixed-top").height();

        $('.search-results-section').css('margin-top', mapContainerOffset);
      };

      $scope.closeDialog = function() {
        $scope.infoWindow.close();
        $timeout(function() {
          $scope.community = null;
        });
      };

      function fitMapToMarkers() {
        var bounds = new google.maps.LatLngBounds();
        $scope.markers.forEach(function(marker) {
          bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
        });
        bounds.extend(
          new google.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getSouthWest().lng() - 0.005),
          new google.maps.LatLng(bounds.getNorthEast().lat() - 0.005, bounds.getSouthWest().lng() + 0.005)
        );
        $scope.map.fitBounds(bounds);
      }

      function setupMap() {
        $scope.map = new google.maps.Map(document.getElementById("map"), {
          mapId: '838174bfafb5e02e',
          center: new google.maps.LatLng(30.262, -97.934),
          zoom: 10,
          clickableIcons: false,
          disableDefaultUI: true,
          zoomControl: true,
          zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
          }
        });
        $scope.infoWindow = document.querySelector("#info-window-community");
        $scope.isMapsLoaded = true;
        $scope.resizeMap();

        google.maps.event.addListener($scope.map, 'bounds_changed', function() {
          $scope.resizeMap();
          if ($scope.markers.length > 0) {
            fitMapToMarkers();
          }
        });

        if ($scope.isMapDataLoaded) {
          setupMarkers();
        }
      }

      function setupMarkers() {
        $scope.markers.forEach(function(marker) {
          marker.setMap(null);
        });

        $scope.markers = [];

        $scope.communities.forEach(function(community) {

          var lat = !community.latitude || isNaN(community.latitude) ? 0 : parseFloat(community.latitude);
          var lng = !community.longitude || isNaN(community.longitude) ? 0 : parseFloat(community.longitude);

          if (!lat || !lng) {
            return;
          }

          var markerContent = document.createElement('div');
          var markerImage = document.createElement('img');
          markerImage.src = '/images/site/blue-icon-pin.png';
          markerImage.alt = community.name;
          markerImage.width = "46";
          markerImage.height = "58";
          markerContent.appendChild(markerImage);

          var marker = new google.maps.marker.AdvancedMarkerElement({
            map: $scope.map,
            position: new google.maps.LatLng(lat, lng),
            content: markerContent
          });

          marker.addListener('click', function() {
            $timeout(function() {
              $scope.community = community;
              $scope.infoWindow.show();
            });
          });

          $scope.markers.push(marker);
          fitMapToMarkers();
        });
      }

      RegionsDataService.regions.then(function(regions) {
        $scope.communities = $scope.region.communities;

        console.log($scope.communities, $scope.region);

        $scope.isMapDataLoaded = true;
        if ($scope.isMapsLoaded) {
          setupMarkers();
        }
      });

      Promise.all([
        google.maps.importLibrary('maps'),
        google.maps.importLibrary('marker')
      ]).then(function() {
        setupMap();
      });
    }


  ]);
