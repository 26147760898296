/*jslint white: true, nomen: true */
/*global angular, google, Promise */
(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.HomePage")

    .controller('whereWeBuildCtrl', ['$scope', 'RegionDataPromiseService',
      function($scope, RegionDataPromiseService) {

        $scope.parent = $scope;

        $scope.regions = [];
        $scope.selectedRegions = [];

        RegionDataPromiseService.promise
          .then(function(result) {
            $scope.regions = _.filter(result.data.regions, function (region) {
              return region.communities.length > 0;
            });
          });

        $scope.toggleSelectRegion = function(region) {
          $scope.selectedRegions = [region];
          $scope.parent.$broadcast("selectedRegion", region.id);
        };
      }
    ])

    .controller('whereWeBuildRegionMapCtrl', ['$scope', '$q', '$timeout', 'RegionDataPromiseService',
      function($scope, $q, $timeout, RegionDataPromiseService) {

        $scope.communities = [];
        $scope.markers = [];
        $scope.map = null;
        $scope.infoWindow = null;
        $scope.community = null;
        $scope.isMapDataLoaded = false;
        $scope.isMapsLoaded = false;

        $scope.closeDialog = function() {
          $scope.infoWindow.close();
        }

        var fitBoundsWithCurrentMarkers = function() {
          var bounds = new google.maps.LatLngBounds();

          $scope.markers.forEach(function(marker) {
            bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
          });

          // add padding around each edge of the bounds
          bounds.extend(
            new google.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getSouthWest().lng() - 0.005),
            new google.maps.LatLng(bounds.getNorthEast().lat() - 0.005, bounds.getSouthWest().lng() + 0.005)
          );

          $scope.map.fitBounds(bounds);

          if ($scope.map.getZoom() > 14) {
            $scope.map.setZoom(14);
          }
        };

        RegionDataPromiseService
          .promise
          .then(function (response) {
            $scope.regions = response.data.regions;
            $scope.communities = $scope.regions.flatMap(function(region) {
              return region.communities;
            });

            $scope.isMapDataLoaded = true;

            if ($scope.isMapsLoaded) {
              initMap();
            }
          });


        var displayRegionMarkers = function(region_id) {
          $scope.markers.forEach(function(marker) {
            marker.setMap(null);
          });

          initMarkers($scope.communities.filter(function(community) {
            return community.region_id == region_id;
          }));
        };

        var matchElemHeightPromise = $q.defer();
        $scope.$on("matchElemHeight", function() {
          matchElemHeightPromise.resolve(true);
        });

        $scope.parent.$on("selectedRegion", function(event, region_id) {
          displayRegionMarkers(region_id);
        });

        function initMarkers(communities) {
          $scope.markers = [];
          communities.forEach(function(community) {
            var lat = community.latitude && !isNaN(community.latitude) ? community.latitude : 0;
            var lng = community.longitude && !isNaN(community.longitude) ? community.longitude : 0;

            if (!lat || !lng) {
              return;
            }

            var markerContent = document.createElement("div");
            var markerImage = document.createElement("img");
            markerImage.src = "/images/site/blue-icon-pin.png";
            markerImage.width = "46";
            markerImage.height = "58";
            markerContent.appendChild(markerImage);

            var marker = new google.maps.marker.AdvancedMarkerElement({
              position: new google.maps.LatLng(lat, lng),
              map: $scope.map,
              gmpClickable: true,
              content: markerContent
            });

            marker.addListener("click", function() {
              $timeout(function() {
                $scope.community = community;
                $scope.infoWindow.show();
              });
            });

            $scope.markers.push(marker);

            fitBoundsWithCurrentMarkers();
          });
        }

        function initMap() {
          $scope.map = new google.maps.Map(document.getElementById("map"), {
            mapId: "838174bfafb5e02e",
            center: new google.maps.LatLng(30.3298767, -97.6948925),
            zoom: 9,
            clickableIcons: false,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            }
          });
          $scope.infoWindow = document.querySelector("#info-window-community");

          initMarkers($scope.communities);
        }

        Promise.all([
          google.maps.importLibrary("maps"),
          google.maps.importLibrary("marker"),
        ]).then(function() {
          $scope.isMapsLoaded = true;
          if ($scope.isMapDataLoaded) {
            initMap();
          }
        });
      }
    ])

    .controller("whereWeBuildRegionSelectorCtrl", ['$scope', 'RegionDataPromiseService', '$timeout',
      function($scope, RegionDataPromiseService, $timeout) {}
    ])
  ;

})();
