(function() {

  'use strict';
  /* global angular */

  angular.module("common")
    .filter('homeFilter', function() {
      return function(homes, homeFilters) {
        return homes.filter(function(home) {
          var valid = true;

          Object.keys(homeFilters).forEach(function(key) {
            if (homeFilters[key] && homeFilters[key] !== 'all') {
              switch (key) {
                case 'regionId':
                  if (home.community.region_id != homeFilters[key]) {
                    valid = false;
                  }
                  break;
                case 'communityId':
                  if (home.community_id != homeFilters[key]) {
                    valid = false;
                  }
                  break;
                case 'beds':
                  if (home.beds != homeFilters[key]) {
                    valid = false;
                  }
                  break;
                case 'baths':
                  if (home.baths != homeFilters[key]) {
                    valid = false;
                  }
                  break;
                case 'sqft':
                  if (home.sqft < homeFilters[key].split('-')[0] || home.sqft >= homeFilters[key].split('-')[1]) {
                    valid = false;
                  }
                  break;
                case 'availability':
                  if (!homeFilters[key] || !home.is_completed) {
                    valid = false;
                    
                  }
                  break;
              }
            }
          });

          return valid;
        });
      };
    });
}());
